import './../styles/normalize.scss'
import './../styles/main.scss'
import './../styles/landing-page.scss'

import React from 'react';

const NotFound = () => {
    return (
        <h1>404</h1>
    );
};

export default NotFound;